<template>
    <section v-if="getActiveStore.role != 'STAFF'">
        <div class="panel has-text-weight-semibold">
        <div class="panel-heading has-background-grey-lighter has-text-weight-bold">
            <div class="columns is-mobile">
                <div class="column is-size-6 has-text-centered">ADDITIONAL INCOME</div>
                <div class="level-right has-text-centered">
                    <b-button  title="Print" type="is-dark" outlined class="is-small is-hidden-mobile" @click="togglePDFDialog">
                        <i class="fas fa-print"></i>
                    </b-button>
                    <!-- <b-button class="is-dark is-small is-hidden-mobile" @click="togglePDFDialog">Print</b-button> -->
                </div>
            </div>
        </div>
        <div class="columns">
            <div class="column is-4">
                <b-field>
                    <date-range-selector
                        :dateRange="salesDateRange"
                        @set-date-range="setDateRange"
                        >
                    </date-range-selector>                    
                </b-field>
            </div>
        </div>
        <div class="panel-heading has-text-weight-bold">
            <div class="columns">

                <div class="column has-text-centered">
                    <div>
                    <p class="is-size-7">Total Income</p>
                    <p class="is-size-6"> {{ totalIncome | formatCurrency }}</p>
                    </div>
                </div>   

            </div>
        </div>
        <div class="panel-block">
            <b-table class="control" 
                :data='records'
                :paginated="isPaginated"
                :per-page="perPage"
                paginationPosition="both"
                sortIcon="arrow-up"
                sortIconSize="is-small"
                default-sort=enteredDate
                defaultSortDirection='asc'
                >

            <template>
                <b-table-column field="enteredDate" label="Date" sortable sorticon  v-slot="props">
                    {{ props.row.enteredDate | formatDate }}
                </b-table-column>

                <b-table-column field="vendorName" label="Vendor" searchable sortable sorticon  v-slot="props">
                   {{ props.row.vendorName }}
                </b-table-column>

                <b-table-column field="description" label="Ref/Desc" searchable sortable sorticon  v-slot="props">
                    {{ props.row.description }}
                </b-table-column>

                <b-table-column numeric field="amount" label="Amount" sortable sorticon  v-slot="props">
                    {{ props.row.amount | formatCurrency }}
                </b-table-column>

            </template>
            
            <template slot="empty">
                <data-loading 
                    :isLoading="isLoading" 
                    :isFullPage="isFullPage"> 
                </data-loading>
            </template>

        </b-table>

        </div>

    </div>

    <income-PDF-report
        ref="pdfReport"
        :isShowPDFDialog="isShowPDFDialog"
        :filteredRecords="records"
        :dateRange="salesDateRange"
        @close-pdf-dialog="togglePDFDialog"
    ></income-PDF-report>    
    </section>
</template>

<script>
import UserStore from "../../../../store/UserStore"

import DataLoading from '../../../../components/app/DataLoading'
import DateRangeSelector from "../../../../components/app/DateRangeSelector"
import IncomePDFReport from "./IncomePDFReport.vue"

import axios from 'axios'

export default {

    components: {
        DataLoading,
        DateRangeSelector,
        IncomePDFReport
    },

    metaInfo: {
        title: 'Additional Income'
    },

    data() {
        return {
            user: [],
            records: [],
            isPaginated: true,
            perPage: 10,
            isLoading: true,
            isFullPage: false,
            isShowDialog: false,
            action: '',
            storeId: 0,
            role: '',
            deleteAllowed: true,
            salesDateRange: [new Date(), new Date()],
            totalIncome: 0.00,
            isShowPDFDialog: false,
        }
    },

    methods: {

        fetchRecords() {

            if (this.user.activeStore) {

                var startDate = this.salesDateRange[0].toISOString().split('T')[0]
                var endDate = this.salesDateRange[1].toISOString().split('T')[0]

                var url = process.env.VUE_APP_API_ROOT_URL + '/stores/' + this.user.activeStore +
                        "/additionalincome?"

                url = url + "fromDate="+startDate+"&toDate="+endDate
                                
                axios.get(url,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + UserStore.getters.getAccessToken,
                            'Refresh-Token': UserStore.getters.getRefreshToken,
                            'correlation-id': this.$uuid.v1()
                        },                   
                    })
                    .then( (response) => {
                        if (response.data.accessToken) UserStore.commit('setAccessToken',response.data.accessToken)
                        if (process.env.VUE_APP_DEBUG == 'true') console.log(response.data)
                        this.records = response.data.data
                        this.setTotalIncome(this.records)
                        this.isLoading = false
                    })
                    .catch( (err) => {
                        if (process.env.VUE_APP_DEBUG == 'true') console.log(err.response.status, err.response.data)
                        this.isLoading = false
                        if (err.response.status === 403) {
                            alert('Session Expired. Please log in again!')
                            UserStore.commit('userLogout')
                        }
                    })
            }
            
        },

        togglePDFDialog() {
            this.isShowPDFDialog = !this.isShowPDFDialog
            if (this.isShowPDFDialog) {
                this.$refs.pdfReport.loadData()
            }
        },

        toggleDialog() {
            this.isShowDialog = ! this.isShowDialog
        },
   
        getEditToolTip(delname) {
            return 'Edit ' + delname
        },

        getDeleteToolTip(delname) {
            return 'Delete ' + delname
        },

        setTotalIncome(income) {
            this.totalIncome = income.reduce((accum,item) => accum + parseFloat(item.amount), 0)
        },

        statusClass() {
            return "has-background-primary"
        },

        doAction(action, expense) {

            this.action = action
            this.storeId = this.user.activeStore

            switch(this.action) {
                case 'add':
                    this.toggleDialog()
                    this.expenseTemp = {
                        "date": new Date(),
                        "dueOn": new Date(),
                        "description": "",
                        "amount": "",
                        "expenseType": "",
                        "creditTerm": "",
                        "vendorId": "",
                        "userId": this.user.id,
                        "storeId": this.storeId,
                    }
                    break

                case 'edit':
                    this.toggleDialog()
                    this.expense = expense
                    this.expenseTemp = Object.assign({}, expense)
                    
                    break

                case 'pay':
                    // console.log("Delete " + expense.name)
                    this.$buefy.dialog.alert('Not Implemented yet!')
                    break
            }

        },

        setDateRange(dateRange) {
            this.salesDateRange = [dateRange[0], dateRange[1]]
        }        
    },

    computed: {

        getActiveStore() {
            return UserStore.getters.getActiveStore
        },

    },

    watch: {
        salesDateRange: function() {
            if (UserStore.getters.getActiveStore.role != 'STAFF') {
                this.fetchRecords()
            } else {
                this.isLoading = false
            }
        },

        dateFilter: function() {
            if (UserStore.getters.getActiveStore.role != 'STAFF') {
                this.records = []
                this.fetchRecords()
            } else {
                this.isLoading = false
            }
        },

    },    

    mounted() {

        this.user = this.getUser()
        var today = new Date()

        if (today.getDate() == 1) {
            today.setMonth(today.getMonth() - 1)
        }
        var firstDay=new Date(today.getFullYear(), today.getMonth(), 1)
        var lastDay = new Date(today.getFullYear(), today.getMonth()+1, 0)
        this.salesDateRange=[firstDay, lastDay]        
        // this.fetchRecords()

    }     

}
</script>

<style>
tr:nth-child(even) {
  background-color: #f2f2f2;
}

.btn-label-text {
    padding-left: 5px;
}
</style>