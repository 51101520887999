<template>
<div class="section">

    <b-modal v-model="isShowPDFDialogLocal" scroll="keep" @close="closePDFDialog">
        <div class="card popupDialog">
            <div class="card-content">
                    <iframe id="pdf" class="pdfiFrame">
                        Your browser doesn't support iframes
                    </iframe> 
            </div>
        </div>
    </b-modal>        

</div>

</template>

<script>

import { PDFDocument, StandardFonts, PageSizes, degrees } from 'pdf-lib'
import moment from 'moment'
import UserStore from "../../../../store/UserStore"

export default {
    props: {
        isShowPDFDialog: Boolean,
        filteredRecords: [],
        dateRange: []
    },

    data() {
        return {
            sortedRecords: [],
            isShowPDFDialogLocal: false,
            totalBookValue: 0,
            totalBookCount: 0,
            totalSoldValue: 0,
            totalBalanceValue: 0,
            dollarUSLocale: Intl.NumberFormat('en-US',{style: 'currency', currency: 'USD'})
        }

    },

    methods: {

        closePDFDialog() {
            this.$emit('close-pdf-dialog')
        },

        createPdf: async(gthis) => {

            const pdfDoc = await PDFDocument.create();
            var page = pdfDoc.addPage(PageSizes.Letter);
            var pageNumber = 1

            const helveticaFont = await pdfDoc.embedFont(StandardFonts.Helvetica)

            await gthis.pageHeader(page,helveticaFont)
            await gthis.veecliLogo(pdfDoc, page)
            var row = 670
            var textWidth = 0
            var rowCount = 0
            gthis.totalValue = 0
            gthis.totalBookCount = 0

            gthis.sortedRecords.forEach(function(item){

                if (rowCount% 35 == 0 && rowCount > 0) {
                    gthis.pageFooter(pdfDoc, page, pageNumber)
                    pageNumber++

                    page = pdfDoc.addPage(PageSizes.Letter)
                    gthis.pageHeader(page,helveticaFont)

                    // Set Line to start records
                    row = 670

                }

                gthis.totalValue +=  item.amount

                page.drawText(moment(item.enteredDate).format('MM/DD/YYYY'), { 
                    x: 20, 
                    y: row, 
                    size: 11, 
                    })

                page.drawText(item.vendorName, { 
                    x: 95, 
                    y: row, 
                    size: 11, 
                    })

                page.drawText(item.description.substr(0,30), { 
                    x: 300, 
                    y: row, 
                    size: 11, 
                    }) 

                textWidth = helveticaFont.widthOfTextAtSize(gthis.dollarUSLocale.format(item.amount), 10)
                
                page.drawText(gthis.dollarUSLocale.format(item.amount), { 
                        x: 590-textWidth, 
                        y: row, 
                        size: 11,
                        TextAlignment: 0,
                        })          

                row -= 3
                page.drawLine({
                    start: { x: 10, y: row },
                    end: { x: 600, y: row },
                    thickness: .5,
                    opacity: 0.20,
                })                 

                row -= 15
                rowCount++

            });

            if (row < 50) {
                    gthis.pageFooter(pdfDoc, page, pageNumber)
                    pageNumber++

                    page = pdfDoc.addPage(PageSizes.Letter)
                    gthis.pageHeader(page,helveticaFont)

                    // Set Line to start records
                    row = 650                
            }

            gthis.pageTotal(page,row-3)
            gthis.pageFooter(pdfDoc,page,pageNumber)

            const pdfDataUri = await pdfDoc.saveAsBase64({ dataUri: true });
            document.getElementById('pdf').src = pdfDataUri;
        },

        pageHeader(page,helveticaFont) {

            var storeName = UserStore.getters.getActiveStore.storeName
            var textWidth = helveticaFont.widthOfTextAtSize(storeName, 15);

            page.drawText(storeName, { 
                x: 300 - (textWidth/2), 
                y: 750, 
                size: 15, 
                })

            var reportHeading = "Additional Income Report"
            textWidth = helveticaFont.widthOfTextAtSize(reportHeading, 13)

            page.drawText(reportHeading, { 
                x: 300 - (textWidth/2), 
                y: 730, 
                size: 13,
                TextAlignment: 1 
                })

            var row = 690
            // Print Column Heading
            page.drawText('Date', { 
                x: 20, 
                y: row, 
                size: 11,
                TextAlignment: 0 
                })

            page.drawText('Vendor', { 
                x: 95, 
                y: row, 
                size: 11,
                TextAlignment: 0 
                })

            page.drawText('Ref/Desc.', { 
                x: 300, 
                y: row, 
                size: 11,
                TextAlignment: 0 
                })                

            page.drawText('Amount', { 
                x: 555, 
                y: row, 
                size: 11,
                TextAlignment: 2
                })

            row -= 4
            page.drawLine({
                start: { x: 10, y: row },
                end: { x: 600, y: row },
                thickness: 1,
                opacity: 0.75,
            })                
                
        },

        veecliLogo: async(pdfDoc, page) => {
            const pngUrl = '/images/veecli-logo.png'
            const pngImageBytes = await fetch(pngUrl).then((res) => res.arrayBuffer())

            const pngImage = await pdfDoc.embedPng(pngImageBytes)
            const pngDims = pngImage.scale(0.08)
            
            page.drawImage(pngImage, {
                x: 300,
                y: 18,
                width: pngDims.width,
                height: pngDims.height,
                rotate: degrees(0),
                // opacity: 0.75,
            })

            page.drawText(" VEECLi.com", { 
                x: 308, 
                y: 20, 
                size: 10,
                TextAlignment: 2
                })                    
        },

        pageFooter(pdfDoc, page, pageNumber) {

            let timeNow = Date()
            timeNow = moment(timeNow).format('MM/DD/YYYY hh:mm a')

            page.drawText(timeNow, { 
                x: 20, 
                y: 20, 
                size: 10,
                TextAlignment: 2
                })

            page.drawText('Page ' + pageNumber, { 
                x: 540, 
                y: 20, 
                size: 10,
                TextAlignment: 2
                })

            // this.veecliLogo(pdfDoc, page)
        },
        
        pageTotal(page,row) {

            page.drawLine({
                start: { x: 10, y: row },
                end: { x: 600, y: row },
                thickness: 1,
                opacity: 0.75,
            })  
            row -= 15



            page.drawText('Total Additional Income: ' + this.dollarUSLocale.format(this.totalValue), { 
                x: 400, 
                y: row, 
                size: 11,
                TextAlignment: 2
                })

            row -= 10
            page.drawLine({
                start: { x: 10, y: row },
                end: { x: 600, y: row },
                thickness: 1,
                opacity: 0.75,
            })  

        },

        loadData() {
            this.sortedRecords = this.filteredRecords.sort(function(a, b){    
                return (a.shiftDate)-(b.shiftDate)
            })
            this.isShowPDFDialogLocal = true
            this.createPdf(this)
        }
    },
  
}
</script>


<style scoped>

.popupDialog {
    /* position:absolute; */
    width:100%;
    height: 100%; 
    max-height: 1000px;
    min-height: 900px;     
}

.pdfiFrame {
    position:absolute; 
    top:0; 
    left:0; 
    bottom:0; 
    /* right:0;  */
    width:100%; 
    height:100%; 
    /* border:none; */
    /* margin-top: 50px; */
    /* margin-bottom: 50px; */
    /* padding:0;  */
    overflow:hidden; 
    /* z-index:999999;    */
}
div{
    height: 100%;
}
</style>